import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

export const AppBarButton = styled(Link)(({ theme }) => ({
    color: theme.palette.menu.textColor,
    textTransform: 'none',
    textDecoration: "none",
    textUnderlineOffset: '5px',
    padding: '0px',
    display:'block',
    '&:hover': {
        color: theme.palette.menu.contrastText,
    },
}));