import { enumDataType, enumConditionalOperator } from "./enums";

const DefaultLocale = 'en-US';

const DateTimeFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
};

const DateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const formatDisplayString = (value, columnProp, locale, dateTimeZone) => {
    if (!value) return value;
    let dispValue = value;
    locale = locale || DefaultLocale;
    switch (columnProp.dataType) {
        case enumDataType.string: {
            dispValue = value;
            break;
        }
        case enumDataType.Number: {
            value = value || 0 ;
            let decimalPoint = columnProp.decimalpoint === undefined ? 2 : columnProp.decimalpoint;
            dispValue = new Intl.NumberFormat(locale).format(parseFloat(value).toFixed(decimalPoint));
            break;
        }
        case enumDataType.Date: {
            value = value.replace("T", " ")
            let date = new Date(value + ' EST');
            dispValue = new Intl.DateTimeFormat(locale, DateFormat).format(date);
            break;
        }
        case enumDataType.DateTime: {
            value = value.replace("T", " ")
            let date = new Date(value + ' EST');
            dispValue = new Intl.DateTimeFormat(locale, DateTimeFormat).format(date);
            break;
        }
        case enumDataType.Amount: {
            value = value || 0;
            let decimalPoint = columnProp.decimalpoint === undefined ? 2 : columnProp.decimalpoint;
            dispValue = new Intl.NumberFormat(locale).format(parseFloat(value).toFixed(decimalPoint));
            break;
        }
        default:
    }
    return dispValue;
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


function descendingComparator(a, b, orderBy, dataType) {
    let left = b[orderBy];
    let right = a[orderBy];
    switch (dataType) {
        case enumDataType.String:
        case enumDataType.Number:
        case enumDataType.Amount:
            break;
        case enumDataType.Date:
        case enumDataType.DateTime:
            left = new Date(b[orderBy]);
            right = new Date(a[orderBy]);
            break;
        default:
            break;
    }
    if (left < right) {
        return -1;
    }
    if (left > right) {
        return 1;
    }
    return 0;
}

export const getComparator = (sort) => {
    let order = sort.direction;
    let orderBy = sort.column;
    let dataType = sort.columnDataType;
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, dataType)
        : (a, b) => -descendingComparator(a, b, orderBy, dataType);
}


/// extract columns from an object array
/// obj - source JSON array / object
/// cols - column name array
/// indexColumn - include index number in every row
//const col = ['test']
//const obj1 = { "test": "1234", "new": 2345 };
//const obj2 = [{ "test": "1234", "new": 2345 }, { "test": "87765", "new": 987 }];
//const obj3 = { "test": { "test": "1234" }, "new": 2345 };
//const obj31 = { "test": [{ "test": "1234" }, { "test": "444" }], "new": 2345 };
//const obj4 = { "test": [{ "test": "1234" }, { "test": "444" }], "new": 2345 };
//const obj5 = [{ "test": [{ "test": "1234" }, { "test": "444" }], "new": { "new": 2345 } }];
//console.log(extractColumn(obj1, col));
//console.log(extractColumn(obj2, col));
//console.log(extractColumn(obj3, col));
//console.log(extractColumn(obj31, col));
//console.log(extractColumn(obj4, col));
//console.log(extractColumn(obj5, col));

export const extractColumn = (obj, cols, indexColumn = false) => {

    if (typeof obj === 'object' && Array.isArray(obj)) {
        const result = obj.map((el, index) => {
            let newEl;
            cols.forEach((c, i) => {
                if (c)
                    newEl = ({ ...newEl, [c]: el[c] });
            })
            return indexColumn ? [newEl, index] : newEl;
        })
        return result;
    }
    else {
        let newEl;
        cols.forEach((c, i) => {
            if (c)
                newEl = ({ ...newEl, [c]: obj[c] });
        })
        return  newEl;
    }
}




/// get unique rows by a property
export const uniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const extractColSort = (array, comparator, cols) => {
    
    const stabilizedThis = extractColumn(array,cols,true);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const removeEmptyProperties=(obj)=>
{
    if (obj === undefined) return obj;
     Object.keys(obj).forEach(k => {
        if (!obj[k] || obj[k] === undefined ||
            (Array.isArray(obj[k]) && obj[k].length === 0)) {
            delete obj[k];
        }
     });
    return obj;
}


export const filterData = (data,filterBy, filterConfig) => {
    return data.filter(o => {
        let match = true;

        filterBy && Object.keys(filterBy).map(k =>

            filterBy[k] ? match = match && filterComparator(filterBy[k], o, filterConfig[k], filterConfig[k]?.searchField || k) : match
        )

        return match;

    });
     
}


/// Left - Filter by value
/// Right - array item
/// fieldConfig - field def
export const filterComparator=(left, right, fieldConfig, searchField)=> {


    if (!left || !fieldConfig) return 1;

    try {
        const operator = fieldConfig?.searchOperator;
        const dataType = fieldConfig.dataType;
        const valField = searchField || fieldConfig.valField;
        right =''+ right[searchField];
        switch (operator) {
            case enumConditionalOperator.Equal:
                if (typeof left === 'object' && !Array.isArray(left)) {
                    left = left[valField]
                }
                return left === right ? 1 : 0
            case enumConditionalOperator.NotEqual:
                return left !== right ? 1 : 0
            case enumConditionalOperator.GreaterThan:
                return left > right ? 1 : 0
            case enumConditionalOperator.GreaterThanOrEqual:
                return left >= right ? 1 : 0
            case enumConditionalOperator.LessThan:
                return left < right ? 1 : 0
            case enumConditionalOperator.LessThanOrEqual:
                return left <= right ? 1 : 0
            case enumConditionalOperator.In:
                if (Array.isArray(left)) {
                    return left.map(i => {
                        if (Array.isArray(i[valField])) {
                            let subArray = i[valField].map(j => '' + j);
                            return subArray.includes(right);
                        }
                        else {
                            return (''+i[valField]).includes(right);
                        }
                    }).includes(true)
                } else if (typeof left === 'object') {
                    if (Array.isArray(left[valField])) {
                        let subArray = left[valField].map(j => '' + j);
                        return subArray.includes(right);
                    }
                    else {
                        return (''+left[valField]).includes(right);
                    }
                }
                throw new Error("Invalid datatype and search operator")
            case enumConditionalOperator.Between:
                if (typeof left === 'object' && !Array.isArray(left)) {
                    const from = left?.from;
                    const to = left?.to;
                    right = getTypeValue(right,  dataType );
                    let result = true;
                    if (from)
                        result = from <= right;
                    if (to && to !== 'Invalid Date' && result)
                        result = to>=right
                    return result;
                }
                
                return 
            default:
                return left === right ? 1 : 0

        }
    } catch (e) {
        console.log(left)
    }
    
    return 1;
}

function getTypeValue(value, dataType) {
    if (!value) return value;
    switch (dataType) {
        case enumDataType.String:
            return value.toString();
        case enumDataType.Number:
        case enumDataType.Amount:
            return Number(value);
        case enumDataType.Date:
            return new Date(value.split(" ")[0]+" EST");
        case enumDataType.DateTime:
            return new Date(value);
        default:
            return value.toString();
    }
}

//function FormatDate(date) {
//    if (!date) return "";

//    function addZ(n) {
//        return (n < 10 ? '0' : '') + n;
//    }
//    return date.getFullYear() + '-' +
//        addZ(date.getMonth() + 1) + '-' +
//        addZ(date.getDate());
//}

/// test data
//const url = "https://localhost/{test}/{new}";
//const obj1 = { "test": "1234", "new": 2345 };
//const obj2 = [{ "test": "1234", "new": 2345 }, { "test": "87765", "new": 987 }];
//const obj3 = { "test": { "test": "1234" }, "new": 2345 };
//const obj31 = { "test": [{ "test": "1234" }, { "test": "444" }], "new": 2345 };
//const obj4 = { "test": [{ "test": "1234" }, { "test": "444" }], "new": 2345 };
//const obj5 = [{ "test": [{ "test": "1234" }, { "test": "444" }], "new": { "new": 2345 } }];
//console.log(ReplaceURLParam(url, obj1));
//console.log(ReplaceURLParam(url, obj2));
//console.log(ReplaceURLParam(url, obj3));
//console.log(ReplaceURLParam(url, obj31));
//console.log(ReplaceURLParam(url, obj4));
//console.log(ReplaceURLParam(url, obj5));

export const ReplaceURLParam = (url, record) => {
    if (!url) return url;
    const urlRegX = /\{([^}]+)\}/gi
    const test = [...url.match(urlRegX)||[]]
    test.map(match => {
        const key = match.replaceAll(/\{|\}/g, "")
        const value = GetURLValueFormObj(record, key);
        url = url.replaceAll(match, value)
        return url;
    })
    return url
}



export const ResolveUrl = (datasource, pageObject) => {
    let url = datasource?.url;
    if (!url) return url;

    const urlRegX = /\{([^}]+)\}/gi
    const test = [...url.match(urlRegX) || []]
    test.map(match => {
        const key = match.replaceAll(/\{|\}/g, "")
        const paramCfg = datasource?.parameters.find(p => p.name === key)
        if (paramCfg) {
            let value = match;
            switch (paramCfg.source) {
                case "urlparams":
                    value = GetURLValueFormObj(pageObject.urlParams, key);
                    break;
                case "pagestore":
                    {
                        let storeValue = pageObject.dataStore.getStoreValue(paramCfg.storeKey);
                        value = GetURLValueFormObj(storeValue?.data, key);
                        break;
                    }
                case "appstore":
                    {
                        let storeValue = pageObject.dataStore.getAppStoreValue(paramCfg.storeKey);
                        value = GetURLValueFormObj(storeValue, key);
                        break;
                    }
                default:
            }
            value = value === match || value === undefined? paramCfg.default?? value: value;
            url = url.replaceAll(match, value)
            
        }
        return url;
    })
    return url
}


const GetURLValueFormObj = (record, key) => {

    if (typeof record === 'object' && Array.isArray(record)) {
        return record.map(j => {
            return GetURLValueFormObj(j[key], key);
        }).join(',');

    }
    else if (typeof record === 'object' && !Array.isArray(record)) {

        if (typeof record[key] === 'object' && !Array.isArray(record[key])) {
            return ('' + record[key][key]);
        } else if (typeof record[key] === 'object' && Array.isArray(record[key])) {
            let subArray = record[key].map(j => '' + j[key]);
            return subArray.join(',');
        }
        else if (typeof record === 'object') {
            return ('' + record[key]);
        }
        else {
            return '' + record;
        }

    } else if (typeof record === 'object') {
        return ('' + record[key]);
    }
    else {
        return record;
    }

}