

const Store = {
    storeshelf: {},
    getStoreValue: function (state_key) {
      //  console.log(state_key);
//        console.log(state_key && this.resolve(state_key, this.storeshelf));
        return state_key && (this.resolve(state_key, this.storeshelf) || undefined);
    },
    setStoreValue: function (state_key, state_value) {
        this.updateStore(state_key, state_value)
        //this.storeshelf[state_key] = state_value;
    },
    removeStoreValue(state_key) {
        this.updateStore(state_key, undefined)
        //this.storeshelf[state_key] = undefined;
    },
    removeStoreValueExcept(keys) {
        let storerack = this.storeshelf;
        Object.keys(storerack).forEach((key) => {
            if (keys.indexOf(key) > 0) {
                this.updateStore(key, undefined)
                //this.storeshelf[key] = undefined;
            }
        })
    }, 
    updateStore(key, value) {
        if (value === undefined) {
            delete this.storeshelf[key];
        }
        else {
            this.storeshelf = { ...this.storeshelf, [key]: value }
        }
        
        return this.storeshelf;
    },
    getAllKeys() {
        return Object.keys(this.storeshelf);
    },
    resolve(path, obj) {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null
        }, obj )
}



}

export const DataStore = {
    getStoreValue: function (state_key) {
        return Store.getStoreValue('pg#' + state_key);
    },
    setStoreValue: function (state_key, state_value) {
        Store.setStoreValue("pg#" + state_key, state_value);
    },
    removeStoreValues(state_keys) {
        state_keys.forEach(key => Store.setStoreValue("pg#"+key, undefined))
        //this.storeshelf[state_key] = undefined;
    },
    removeStoreValue(state_key) {
        Store.removeStoreValue("pg#" + state_key)
        //this.storeshelf[state_key] = undefined;
    },
    getAllKeys() {
        const regex = new RegExp('^pg#', 'i');
        const keys = Store.getAllKeys();
        return keys && keys?.filter(r => regex.test(r)).map(r=> r.replace("pg#",""));
    },

    getAppStoreValue: function (state_key) {
        return Store.getStoreValue('app#' + state_key);
    },
    setAppStoreValue: function (state_key, state_value) {
        Store.setStoreValue("app#" + state_key, state_value);
    },
    removeAppStoreValues(state_keys) {
        state_keys.forEach(key => Store.setStoreValue("app#" + key, undefined))
        //this.storeshelf[state_key] = undefined;
    },
    removeAppStoreValue(state_key) {
        Store.removeStoreValue("app#"+state_key)
    },
    getAllAppKeys() {
        const regex = new RegExp('^app#', 'i');
        const keys = Store.getAllKeys();
        return keys && keys?.filter(r => regex.test(r)).map(r => r.replace("app#", ""));;
    },
}



