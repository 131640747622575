import React from "react";

import { Typography, Container, Box,Icon,Grid } from '@mui/material';
import useWindowDimensions from "../Hooks/UseWindowDimensions";


export const NotFound = (props) => {
    
    const { height } = useWindowDimensions();
    return (
        <Container sx={{
            display: 'flex',
            height: height-(height-150)/2,
            maxWidth: '1280px',
            m: 1,
            mx: 'auto',
            my:'auto',
            p: 1,
            alignItems: 'center'
        }} maxWidth={false}>
            <Box sx={{ height: '100%', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid >
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Icon fontSize="large" color="secondary">warning</Icon>
                        <Typography variant="h5" color="secondary">404 - Not Found</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="body1">Requested resource is not found.</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
};