export const enumDataType = Object.freeze({
    String: "string",
    Number: "number",
    Date: "date",
    DateTime: "datetime",
    Amount: "amount"
});

export const enumCtrlType = Object.freeze({
    Label: "label",
    HyperLink: "hyperlink",
    NavLink: "navlink",
    Button: "button",
    Icon: "icon",
    IconButton: "iconbutton",
    AutoComplete: "autocomplete",
    DropDown: "dropdown",
    TextField: "textfield",
    CheckBox: "checkbox",
    RadioGroup: "radiogroup",
    TextArea: "textarea",
    DateTime: "datetime",
    Date: "date",
    DateRange:"daterange",
    Time: "time",
    label:"label"
});

export const enumSortDirection = Object.freeze({
    Asc: "asc",
    Desc: "desc"
});

export const enumConditionalOperator = Object.freeze({
    Equal: "eq",
    NotEqual: "nteq",
    GreaterThan: "grthan",
    GreaterThanOrEqual: "grtharneq",
    LessThan: "lsthan",
    LessThanOrEqual: "lsthanreq",
    In: "in",
    Between:"between"
})


export const enumActions = Object.freeze({
    ShowSearchForm: "showSearchForm",
    HideSearchForm: "hideSearchForm",
    SearchData: "searchData",
    ResetSearch: "resetSearch",
    Export: "export",
});

export const enumFormType = Object.freeze({
    Search: "search",
    Add: "Add",
    Edit: "edit",
    Detail: "detail"
});

export const enumPageComponentType = Object.freeze({
    Form: "form",
    Grid: "grid",
    ActionBar: "actionbar",
    Repeater: "repeater"
});

export const enumDataGridActions = Object.freeze({
    FirstPage: "firstPage",
    LastPage: "lastPage",
    NextPage: "nextPage",
    PreviousPage: "prevPage",
    SortGrid: "sortGrid",
    RowsPerPage: "rowsPerPage",
    Navigate: "navigate"

});


