import React from 'react';
import {
	Box, CircularProgress
} from '@mui/material';
import useWindowDimensions from "../Hooks/UseWindowDimensions";



export const Progress = (props) => {
	
	const { width, height } = useWindowDimensions();
	return (
		<Box sx={{
			position: 'absolute', top: 75, left: 0, zIndex: 9999, width: width, height: height - 125,
			alignItems: 'center', backgroundColor: 'modelBgColor',
			justifyContent: 'center'
		}}>
			<Box sx={{
				 display:'flex',alignItems: 'center', height:'100%',
				justifyContent: 'center'
			}}>
				<CircularProgress m="50" /></Box>
		</Box>
	)

}