export const HomePageConfig = [
   {
         id: 'P2',
         title: "Orders",
         alt: "View Orders",
         image: "/static/images/dashboard/orders.jpg",
         description: "See a list of current and past order. Search and filter. View order details",
     },
    {
        id: 'P3',
        title: "Inventory",
        alt: "View Inventory",
        image: "/static/images/dashboard/inventory.jpg",
        description: "View all label and finished good inventory",
    },
    {
        id: 'P4',
        title: "Shipments",
        alt: "View Shipments",
        image: "/static/images/dashboard/shipments.jpg",
        description: "See status and details on all shipments",
    },
    {
        id: 'P5',
        title: "Invoices",
        alt: "View Invoices",
        image: "/static/images/dashboard/invoices.jpg",
        description: "Find invoices with line item details",
    },



]