import React, { useEffect, useState, Suspense, useRef } from "react";
import {
    Box, Typography,
    Container, Stack, Button, Icon
} from '@mui/material';
import { callApi } from "../fetch";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../Hooks/UseWindowDimensions";
import { Progress } from '../components/Progress';
import { ResolveUrl } from "../Utility";
import { DataStore } from "../DataStore";
import { AppCustomEvents } from "../AppCode/AppCustomEvents"
const ComponentWrapper = React.lazy(() => import('../components/ComponentWrapper').then(module => ({ default: module.ComponentWrapper })));

export const Detail = (props) => {
    const { height } = useWindowDimensions();
    const configFile = props.config;
    const navigate = useNavigate();
    const location = useLocation();
    let urlParams = useParams()
    const abortController = useRef(new global.AbortController())

    const [pageConfigName, setPageConfigName] = useState();
    const [pageState, setPageState] = useState({});
    const [pageLoading, setPageLoading] = useState({});
   
    
    const pageConfig = DataStore.getStoreValue(pageConfigName)?.config ;

    const GetPageObject = (pageName) => {
        return {
            name: pageName,
            configFileName: configFile,
            location: location.pathname,
            urlParams: urlParams,
            queryParams: location.search,
            callApi: callApi,
            dataStore: DataStore,
            navigate: navigate
        };
    }
    
    useEffect(() => {
        setPageLoading(true);
        
        const controller = abortController.current
        const onPageLoad = async (pgConfig) => {
                  
            const onBeforePageLoadFn = pgConfig?.page?.onBeforeLoad ? AppCustomEvents[pgConfig.page.onBeforeLoad] ?? undefined : undefined;
            const onAfterPageLoadFn = pgConfig?.page?.onAfterLoad ? AppCustomEvents[pgConfig.page.onAfterLoad] ?? undefined : undefined;
            let isonPageLoadFnCompleted = true;
            const pageObject = GetPageObject(pgConfig.name);
            isonPageLoadFnCompleted = typeof onBeforePageLoadFn === "function" ? await onBeforePageLoadFn.call({ ...pageObject }) : await true;

            setTimeout(() => setPageConfigName(pgConfig.name), 100)

            
            if (isonPageLoadFnCompleted) {
                //const url = ReplaceURLParam(pgConfig.page?.dataSource?.url, urlParams)
                const url = ResolveUrl(pgConfig.page?.dataSource, { ...pageObject })
                if (url) {
                    let storObj =
                    {
                        config: {
                            ...pgConfig
                        },
                        data: await callApi(url, abortController.signal)
                    }

                    DataStore.setStoreValue(pgConfig.name, { ...storObj });
                    storObj = null;
                }
                isonPageLoadFnCompleted = typeof onAfterPageLoadFn === "function" ? await onAfterPageLoadFn.call({ ...pageObject }) : await true;
                setPageState({ [pgConfig.name]: true });
                setPageLoading(false);
            }
        }


        import("../Configs/" + configFile + ".js")
            .then(config => {
                let pgConfig = config[configFile];
                DataStore.setStoreValue(pgConfig.name, { config: { ...pgConfig } });
               
                onPageLoad(pgConfig);

            }).catch(error => {
                console.log(error)
            });
               
        return () => {
            console.log("datastore cleaned up");
            controller.abort();
            const storekey = DataStore.getAllKeys();
            if (storekey.length > 0)
                DataStore.removeStoreValues(storekey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configFile, location])

    return (
        <> 
            <Container maxWidth="sm md lg xl" maxHeight={height} >
                <Box sx={{ overflow: 'auto', maxHeight: height-150, mx:-2.5 }}>
                {pageConfigName && <Stack spacing={0} sx={{ mx:2 }}>
                    <Box sx={{ m: 2 }} data-qa-element="pageHeader">
                        <Typography variant="h6">{pageConfig?.page?.title}</Typography>
                        </Box>
                        {pageConfig?.page?.backNavigation !== false  &&  <Box sx={{ mt: -2.5, mb: 0, mx: 2 }}>
                            <Stack direction="row" justifyContent="flex-start" spacing={1} data-qa-container="buttonBar">
                                <Button size="small" onClick={() => navigate(-1, { replace: true })}> <Icon>arrow_left</Icon> Back </Button>
                            </Stack>
                        </Box>}
                        <Suspense fallback={<Progress></Progress>}>
                    {pageState[pageConfig?.name] && pageConfig?.pageComponents.map((componentConfig, index) => {
                       // return (RenderComponent(componentConfig, index))
                        return (<Box sx={{ m: 2 }} key={"comp"+index }> <ComponentWrapper config = { componentConfig } ></ComponentWrapper></Box>)
                    })}
                            </Suspense>
                    </Stack>}
                    {pageLoading ? <Progress></Progress>:""}
                </Box>

            </Container>

        </>
    )
};

export let EmptyComponent = () => { return <>Loading...</> }

