import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let PortalTheme = createTheme({});
// Create a theme instance.

PortalTheme = createTheme(PortalTheme,{
    palette: {
        menu: {
            main: '#002169',
            //main: '#004990', -- Original Sonoco.com color
            textColor: "#fff",
            contrastText: "#f18a00",
            
        },
        link: {
            main: '#002169',
            //main: '#004990', -- Original Sonoco.com color
            textColor: "#fff",
            contrastText: "#f18a00",

        },
        submenu: {
            main: '#002169',
            contrastText: "#fff",

        },
        primary: {
            main: '#0076bb',
            light: '#91bfe9'
        },
        secondary: {
            main: '#e43d2f',
            light: "#f18a00"
        },
        error: {
            main: '#e43d2f'
        },
        footer: {
            main: '#020b19',
            textColor: '#fff',
            contrastText: '#0076bb'
        },
        backColor: '#f2f2f3',
        modelBgColor: 'rgba(255, 255, 255, 0.25)'
        

    },
    typography: {
        fontFamily: [
            'proxima-nova', 'Arial', 'Helvetica', 'sans - serif'
        ].join(','),
        fontSize: 12,
        navlink: {
            fontSize: 18,
            fontWeight:700,
            cursor: 'pointer' 
        },
        body2: {
            [PortalTheme.breakpoints.down('md')]: {
                fontSize: 10,
            },
            [PortalTheme.breakpoints.down('lg')]: {
                fontSize: 11,
            },
            [PortalTheme.breakpoints.up('lg')]: {
                fontSize: 12,
            },
            [PortalTheme.breakpoints.up('xl')]: {
                fontSize: 13,
            },
        },
        body1: {
            [PortalTheme.breakpoints.down('md')]: {
                fontSize: 11,
            },
            [PortalTheme.breakpoints.down('lg')]: {
                fontSize: 12,
            },
            [PortalTheme.breakpoints.up('lg')]: {
                fontSize: 13,
            },
            [PortalTheme.breakpoints.up('xl')]: {
                fontSize: 14,
            },
        }, h6: {
            [PortalTheme.breakpoints.down('md')]: {
                fontSize: 14,
            },
            [PortalTheme.breakpoints.down('lg')]: {
                fontSize: 15,
            },
            [PortalTheme.breakpoints.up('lg')]: {
                fontSize: 15,
            },
            [PortalTheme.breakpoints.up('xl')]: {
                fontSize: 16,
            },
        },
        h5: {
            [PortalTheme.breakpoints.down('md')]: {
                fontSize: 16,
            },
            [PortalTheme.breakpoints.down('lg')]: {
                fontSize: 17,
            },
            [PortalTheme.breakpoints.up('lg')]: {
                fontSize: 17,
            },
            [PortalTheme.breakpoints.up('xl')]: {
                fontSize: 18,
            },
        },


    },
    components: {
        // Name of the component
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: '75px'
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                   padding:6,
                    maxWidth:275,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                   fontSize:'12px',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                  fontSize:12,
                    fontWeight:500,
                    height:'24px',
                },
               label:{
                    paddingLeft:6,
                    paddingRight:6,
                },
                deleteIcon:{
                margin:'0 2px 0 -3px'
                }
            },
        },
        
            MuiInputLabel: {
                styleOverrides: {
                    standard: {
                        '&.MuiInputLabel-shrink': {
                            [PortalTheme.breakpoints.down('md')]: {
                                fontSize: 14,
                            },
                            [PortalTheme.breakpoints.down('lg')]: {
                                fontSize: 14,
                            },
                            [PortalTheme.breakpoints.up('lg')]: {
                                fontSize: 15,
                            },
                            [PortalTheme.breakpoints.up('xl')]: {
                                fontSize: 15,
                            },
                        },
                    }
                   
                },
            },
            
       
    },
});
PortalTheme = responsiveFontSizes(PortalTheme)
export default PortalTheme;