import React, { useState, useMemo, memo } from "react";

import { useNavigate } from "react-router-dom";
import { RouteConfig } from "../../Configs/RouteConfig"

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Icon from "@mui/material/Icon";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { appConfig } from "../../authConfig";
import { AppBarButton } from "./AppBarButton"
//import { getProfilePhotoURL, getUserProfile } from "../../graph";
import { useLocation } from "react-router-dom";
import MsalHelper from "../../MsalHelper";
import { getUserPermissionSession } from "../../fetch";

export const AppNavBar = memo((props) => {
    const isAuthenticated = useIsAuthenticated();
    const instance = MsalHelper.getAppInstance();
    const name = MsalHelper.getName();
    const username = MsalHelper.getEmail();
    const [anchorElNav, setAnchorElNav] = useState(null);
    //const [imageUrl, setImageUrl] = useState(null)
    //const [userData, setUserData] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    
   // const active = location.state?.url || "/" ;
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    
        
    const permission = getUserPermissionSession(RouteConfig.name);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
   
const IsSelected = (pageurl)=>{
    if(pageurl !== "/")
    {
        const regex = new RegExp(':.*?(?=/|$)','g')
        const regexPage = new RegExp("^"+pageurl.replace(regex,"(.*)"),'i')
        return regexPage.test(location.pathname);
    }
    else{
        return pageurl === location.pathname
    }
}
    
    //useEffect(() => {
       
    //    //getProfilePhotoURL(instance, accounts[0], "/photos/64x64/$value").then((data) => {
    //    //    window.URL = window.URL || window.webkitURL;
    //    //    let url = window.URL.createObjectURL(data);
    //    //    setImageUrl(url);
    //    //})
    //    //getUserProfile(instance, accounts[0], "?$select=displayName,mail,userType,userPrincipalName,id").then((response) => {
    //    //    setUserData(response);
    //    //})
    //}, [instance, accounts])
   
    return (
        <>
            <AppBar data-qa-container="appBar" position="fixed" color="submenu" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>

                    <Box sx={{ flexGrow: 1, display: { xs: 'contents', md: 'none', } }}>
                        
                        <Icon size="large" edge="start"
                            aria-label="menu"
                            onClick={handleOpenNavMenu}
                            data-qa-element="navMenuButton"
                            sx={{ mr: 1 }}>menu</Icon>
                        <Menu data-qa-container="navmenu"
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {RouteConfig.routes.filter(item => item.type === "topmenu" && (permission?.some(p => p.toLowerCase() === item.caption.toLowerCase()) || item.caption === "Home")).map((page) => (
                                <MenuItem data-qa-element={page.caption } key={page.id} onClick={(e) => { handleCloseNavMenu(); navigate(page.url, { state: { title: page.caption, url: page.url } }); }}> 
                                    <Typography textAlign="center" style={{ cursor: 'pointer', textDecoration:IsSelected(page.url)  ? "underline" : "none" }}>{page.caption}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {useMemo(() => <Box
                        variant="h5"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex' },
                            flexGrow: 1,
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Box sx={{
                            mr: 2,
                            display: { xs: 'flex' },
                            flexGrow: 1

                        }} class="sonocoLogo"></Box>



                        <Box sx={{
                            justifyCotent: 'center',
                            //display: { xs: 'none', md: 'none', lg: 'flex' },
                            flexGrow: 1,
                        }} class="headerTitle">
                            <Typography variant='h5' noWrap
                                sx={{

                                    display: 'flex',
                                    flexGrow: 1,
                                    color: '#fff',
                                    textAlign: "center",
                                    height: '100%',
                                    fontFamily: "proxima-nova,Arial,Helvetica,sans-serif"

                                }}>
                                {appConfig.name} {process.env.REACT_APP_ENV === "PROD" ? "" : "(" + process.env.REACT_APP_ENV + ")"}
                            </Typography>
                        </Box>
                    </Box>,[]) }
                    <Stack direction="row" spacing={3}sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', 'justifyContent': 'flex-end', alignItems: 'center' } }} >
                        {isAuthenticated ? <>
                            {RouteConfig.routes.filter(item => item.type === "topmenu" && (permission?.some(p => p.toLowerCase() === item.caption.toLowerCase())|| item.caption==="Home" )).map((page) => (
                            
                                <AppBarButton 
                                data-qa-element={page.caption}
                                component={Link}
                                key={page.id}
                                onClick={(e) => { navigate(page.url, { state: { title: page.caption, url: page.url } }); }}>
                                    <Typography style={{ cursor: 'pointer', textDecoration:IsSelected(page.url)  ? "underline" : "none" }} variant="navlink">{page.caption}</Typography>
                            </AppBarButton>
                        ))}
                            <SignOutButton name={name} username={username} instance={instance} navigate = {navigate} /> </> : <SignInButton login={MsalHelper.login} />}
                                                    
                    </Stack>


                </Toolbar>
            </AppBar>
        </>
    );
});