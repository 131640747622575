import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Icon from "@mui/material/Icon";


const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.footer.textColor,
    '&:hover': {
        color: theme.palette.footer.contrastText,
    },
}));

export const Footer = (props) => {
    const [ displayFooter, setDisplayFooter ] = useState(false);
    const toggleFooter = () => {
        setDisplayFooter(!displayFooter);
    }

    return (<Box
        sx={{
            width: '100%',
            backgroundColor: 'footer.main',
            color: 'footer.textColor',
            position: 'fixed', /* absolute */
            bottom: '0px',
            zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
    >
        {displayFooter === true ?
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
            sx={{ padding: '10px 100px 10px 100px' } }
        >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }} >SONOCO</Typography>
                <FooterLink variant="body2" underline="none" color="footer" href="https://www.sonoco.com/about/supplier-resources/california-transparency-supply-chains-act" target="_blank"> California Transparency in Supply Chains Act</FooterLink>
                <FooterLink variant="body2" underline="none" color="footer.textColor" href="https://www.sonoco.com/about/legal" target="_blank">Legal</FooterLink>
                <FooterLink variant="body2" underline="none" color="footer.textColor" href="https://www.sonoco.com/about/privacy" target="_blank">Privacy</FooterLink>
                </Stack>
           
            
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Typography variant="subtitle2" sx={{fontWeight:700} } >CONNECT WITH SONOCO</Typography> 
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                    >
                        <FooterLink class="footer-icon facebook" href="https://www.facebook.com/sonoco.products" target="_blank"></FooterLink>
                        <FooterLink class="footer-icon twitter" href="https://twitter.com/sonoco_products?lang=en" target="_blank"></FooterLink>
                        <FooterLink class="footer-icon youtube" href="https://www.youtube.com/channel/UC8xq1feSR46htmO7mGmDjYQ" target="_blank"></FooterLink>
                        <FooterLink class="footer-icon linkedin" href="https://www.linkedin.com/company/sonoco" target="_blank"></FooterLink>
                        <FooterLink class="footer-icon instagram" href="https://www.instagram.com/sonocoproducts/" target="_blank"></FooterLink>
                  </Stack>
                
            </Stack>
                <Typography component={Link} onClick={() => { console.log(displayFooter); toggleFooter() }} sx={{ color: 'white' }}>
                    <Icon size="small" sx={{ fontSize: 16 }}  >cancel</Icon>
                </Typography>
        </Stack>
        :""}
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
            sx={{ padding: '10px 100px 10px 100px', }}
        >
            <Typography variant="body2" sx={{ fontSize: 14 }} >Copyright<Icon size="small" sx={{ fontSize: 14 }} >copyright</Icon> {(new Date().getFullYear())} Sonoco Products Company</Typography>
            {displayFooter === false ?
                <Typography component={Link} onClick={() => { console.log(displayFooter); toggleFooter() }} sx={{ color: 'white' }}>
                    <Icon size="small" >expand_less</Icon>
                </Typography> : ""}
            
        </Stack>
        </Box>);
}
