import React from "react";
import { useNavigate } from "react-router-dom";
import { RouteConfig } from "../Configs/RouteConfig"
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from "@mui/material/Icon";
import { HomePageConfig } from "../Configs/HomePageConfig"
import { getUserPermissionSession } from "../fetch";
import useWindowDimensions from "../Hooks/UseWindowDimensions";

export const Dashboard = (props) => {
    const navigate = useNavigate();
    const { height } = useWindowDimensions();
    const permission = getUserPermissionSession();;
    return (
        <Container sx={{
            display: 'flex',
            //height: 'inherit',
            maxWidth: '1280px',
            maxHeight: height-125,
            overflow:'auto',
            justifyContent: 'center'
            }}  > 
           

<Box sx={{py:3}} >
                 
                <Grid container spacing={{ xs: 3, sm:3, md: 3, lg: 3 }} columns={{ xs: 1, sm:2, md: 3, lg: 4 }} align='center' sx={{pb:2}}>
            <Grid item xs={12} >
                <Typography variant="h5"> Manage Your Operation with the Sonoco Partner Portal </Typography>
                <Typography variant="body1" > The Sonoco Partner Portal is designed to give you up-to-date, accurate information on your business with Sonoco </Typography>
            </Grid>
                        {HomePageConfig.filter(item => permission && permission.some(p => p.toLowerCase() === item.title.toLowerCase())).map((tile) => (
                        <Grid item xs={1} md={1} key={"d" + tile.id}>
                            <Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 350, height: 370, borderRadius: 1, boxShadow: 2,  }}  >
                                <CardMedia
                                    component="img"
                                    image={tile.image}
                                    alt={tile.alt}
                                    sx={{cursor:'pointer'}}
                                       onClick={(e) => {
                                           var route = RouteConfig.routes.find(item => item.caption === tile.title );
                                            navigate(route.url, { state: { title: route.caption, url: route.url } });
                                        }
                                        }
                                />
                                <CardContent sx={{ p: 1, }} >
                                    <Typography gutterBottom variant="h6" component="div">
                                        {tile.title}
                                    </Typography>
                                    <Typography variant="body2" component="p" sx={{textAlign:'left'}}>
                                        {tile.description}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ marginTop: 'auto' }}>
                                    <Button 
                                        onClick={(e) => {
                                                var route = RouteConfig.routes.find(item => item.caption === tile.title);
                                            navigate(route.url, { state: { title: route.caption, url: route.url } });
                                        }
                                        }
                                        sx={{
                                            backgroundColor: '#fff',
                                            color: 'primary',//'#3c52b2',
                                            border: 1,
                                            fontWeight: "bold",
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: '#0076bb',
                                                color: '#fff',
                                            },
                                        }}
                                    >{tile.alt}
                                        <Icon>east</Icon>
                                    </Button>
                                </CardActions>
                            </Card>

                        </Grid>
                    ))}
                </Grid>
 
</Box>
            
           
        </Container>
      )
};