import React, {  useEffect, useState } from "react";
import { Routes, Route} from "react-router-dom";
import { CustomBrowserRouter } from "./CustomBrowserRouter"
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { ThemeProvider } from '@mui/material/styles'
import PortalTheme from "./PortalTheme"
import { RouteConfig } from "./Configs/RouteConfig";
import { getUserPermission, callApi } from "./fetch";
import { AppCustomEvents } from "./AppCode/AppCustomEvents";
import { DataStore } from "./DataStore";
import MsalHelper from "./MsalHelper";



function App() {
    const [userPermission, setUserPermission] = useState();
    const [appReady, setAppReady] = useState();
    const isAuthenticated = useIsAuthenticated();
    
    useEffect(() => {

        const onAppLoad = async function () {

            //display the app navbar
            setAppReady(true);
            // display the navision in appnavbar
            const permission = await getUserPermission(RouteConfig.dataSource.url)
            setUserPermission(permission);

            // load custom appload
            const onAppLoadFn = AppCustomEvents["onAppLoad"];

            const appObject = {
                callApi: callApi,
                dataStore: DataStore,
            }
            
            const isonAppLoadFnCompleted = typeof onAppLoadFn === "function" ? await onAppLoadFn.call(appObject) : await false;
            if (isonAppLoadFnCompleted) {
                

            }
        }
        
        if (isAuthenticated && !appReady) {
            DataStore.setAppStoreValue("username", MsalHelper.getName());
            onAppLoad();
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    return (
        <CustomBrowserRouter>
            <ThemeProvider theme={PortalTheme}>

                <AuthenticatedTemplate>

                    {appReady === true ?
                        <PageLayout>
                            <Pages permission={userPermission}></Pages>
                        </PageLayout>
                        : ""}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <PageLayout>

                        <p>You are not signed in! Please sign in.</p>
                    </PageLayout>
                </UnauthenticatedTemplate>
            </ThemeProvider>
        </CustomBrowserRouter>
    );
}
const Pages = (props) => {
    const unRestricatedRoutes = RouteConfig.routes.filter(r => r.restricted !== true).map(r => r["caption"]);
    const routeAllowed = (props.permission ?? []).concat(unRestricatedRoutes);
    //console.log(routeAllowed)

    return (
        <Routes>
            {RouteConfig.routes
                .filter(route => routeAllowed.some(p => p.toLowerCase() === route.caption.toLowerCase()))
                .map((route, i) => (<Route key={"route" + i} path={route.url} element={<route.component {...route} />}></Route>))}
            
        </Routes > 
        
    )
}




export default App;