import { callApi } from "../fetch";
// write your own method and assign the method name in config
export const AppCustomEvents = {
	onAppLoad: async function () {
		//check this. object
		console.log("custom function onAppLoad")
		// not implemented yet

		return true;
	},

	// onPageLoad will be executed before execution of dataSource URL in config
	onCustomBeforePageLoad: async function () {
		console.log("custom function onCustomBeforePageLoad:" + this.name)
		const urlParams = this?.urlParams;
		const customerid = urlParams["customerid"];
		this.dataStore.setAppStoreValue("redirectLocation", this.location);
		if (customerid && customerid === ":customerid") {
			let url = "";
			console.log("customer id is missing")
			// if the customer id is available in datastore, update the url and redirect
			let customer = this.dataStore.getAppStoreValue("selectedCustomer");
			if (customer && customer.CustomerNumber) {
				url = this.location.replace(":customerid", customer?.CustomerNumber);
				setTimeout(() => this.navigate(url, { replace: true }), 10);
				return false;
			}
			else {
				customer = localStorage.getItem("preferedCustomer");
				customer = JSON.parse(customer);
				// else navigate to customer selection
				if (customer && customer.CustomerNumber) {
					url = this.location.replace(":customerid", customer?.CustomerNumber);
					setTimeout(() => this.navigate(url, { replace: true }), 10);
					return false;
				}
				else {
					setTimeout(() => this.navigate("/customer"), 10)
				}
			}
			return false;
		}
		else {
			// update in datastore
			customerid && this.dataStore.setAppStoreValue("selectedCustomer", { "CustomerNumber": customerid })
		}
		//returns true or false
		return true;
	},
	onCustomAfterPageLoad: async function () {
		console.log("custom function onPageAfterLoad:" + this.name)

		const urlParams = this?.urlParams;
		const customerid = urlParams["customerid"];
		const url = "/UserCustomers";
		let customers = [];
		let getCustomers = false;

		let customer = this.dataStore.getAppStoreValue("selectedCustomer")  
		if (customer && customer.CustomerNumber !== customerid) {
			customer = JSON.parse(localStorage.getItem("preferedCustomer"));
			if (customer && customer.CustomerNumber === customerid) {
				if (customer.CustomerName === undefined)
					getCustomers = true;
			}
			else getCustomers = true;
		}
		else {
			if (customer.CustomerName === undefined) {
				getCustomers = true;
			}
        }

		if (getCustomers) {
			customers = await callApi(url);
			customer = (customers.filter(cust => cust.CustomerNumber === customerid) ?? [])[0];
        }

		let title = document.querySelectorAll("[data-qa-element='pageHeader']");
		//let className = title[0]?.children[0].className;
		//if (title[0]?.children['subtitle'])
		//	title[0]?.children['subtitle'].remove();
		//title[0]?.insertAdjacentHTML('beforeend', '<span id="subtitle" class="' + className + '"> - ' + customer.CustomerName ?? customer.CustomerNumber + '</span>');

		let customerInfo = " - " + customer.CustomerName.trim() ?? customer.CustomerNumber;
		let titleText = title[0].children[0].innerText.replace(customerInfo, '');
		title[0].children[0].innerText = titleText + customerInfo;

		customerid && this.dataStore.setAppStoreValue("selectedCustomer", customer) && localStorage.setItem("preferedCustomer", JSON.stringify(customer));

		return true;
	},

	// always returns the true / false
	// it runs before the form object updates
	onCustomComponentLoad: async function () {
		// not implemented yet
	},


	onCustomomerPageAfterLoad: async function () {
		console.log("custom function onCustomomerPageAfterLoad:" + this.name)
		let customers = this.dataStore.getStoreValue("customers.data");
		if (customers && customers.length === 1) {
			localStorage.setItem("preferedCustomer", JSON.stringify(customers[0]));
			let url = this.dataStore.getAppStoreValue("redirectLocation")?.replace(":customerid", customers[0]?.CustomerNumber);
			this.dataStore.removeAppStoreValue("redirectLocation");
			if (url)
				setTimeout(() => this.navigate(url, { replace: true }), 10);
			else
				console.log("no redirect location found in datastore.")
        }
		return true;
	},

	// onBeforeChange always reutns value
	onSelectCustomer: async function (value) {	
		if (value && value.CustomerNumber) {
			this.dataStore.setAppStoreValue("selectedCustomer", value);
			localStorage.setItem("preferedCustomer", JSON.stringify(value));

			// eslint-disable-next-line
			let regexExpr = /([^\/]+)/i; // not adding 'g' modifier because we just want to return/replace first match.
			let url = this.dataStore.getAppStoreValue("redirectLocation")?.replace(regexExpr, value?.CustomerNumber);
			url = url?? "/"
			this.dataStore.removeAppStoreValue("redirectLocation");
			if(url)
				setTimeout(() => this.navigate(url, { replace: true }), 10);
			else
				console.log("no redirect location found in datastore.")
		}
		return value;
	}
	

}