import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig, appResources, loginRequest } from "./authConfig";
const msalInstance = new PublicClientApplication(msalConfig);

const MsalHelper = {
    login() {
        msalInstance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    },
    getAppInstance() {
        return msalInstance;
    },
    getAccount(){
        return msalInstance.getAllAccounts()[0];
    },
    getName() {
        return msalInstance.getAllAccounts()[0]?.name
    },
    getEmail() {
        return msalInstance.getAllAccounts()[0]?.username
    },
    getAccessToken()
    {
        const apiRequest = {
            scopes: appResources.scopes,
            account: this.getAccount()
        };
        return msalInstance.acquireTokenSilent(apiRequest).then((response) => response.accessToken).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(apiRequest).then((response) => response.accessToken);
            }
        });
    }
}



export default MsalHelper;