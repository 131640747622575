
import { appResources } from "./authConfig";
import MsalHelper from "./MsalHelper";
import history from "./history"


const noAccessResponse = new RegExp(["undefined", "null", '"status":( ?)401', '"StatusCode":( ?)401', '"status":( ?)403', '"StatusCode":( ?)403'].join('|'), 'gi');
const notfoundResponse = new RegExp(['"StatusCode":( ?)404', '"status":( ?)404'].join('|'), 'gi');
const errorResponse = new RegExp(["undefined", "null", '"StatusCode":( ?)500', '"StatusCode":( ?)502','"StatusCode":( ?)501','"StatusCode":( ?)503'].join('|'), 'gi');
export async function callApi(apiEndpoint, abortSignal) {
    const _history = history;  
    return MsalHelper.getAccessToken().then((accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);        
        return getAuthzToken(headers).then((response) => {
            if (response === "" || noAccessResponse.test(response)) {
                _history.push("/noaccess")
            }
            else if (notfoundResponse.test(response))
            {
                _history.push('/notfound')
            }
            else if (errorResponse.test(response)) {
                _history.push("/error")
            }
            else {
                const authzToken = response;
                sessionStorage.setItem("authzToken", authzToken);
                headers.append("AuthzToken", authzToken);
                headers.append("Content-Type", 'application/json');
                const options = {
                    method: "GET",
                    headers: headers,
                    signal: abortSignal
                };
                return callFetch(appResources.appEndpoint + apiEndpoint, options)
                    .then(response => {
                        if (response === "" || noAccessResponse.test(response))
                            _history.push("/noaccess")
                        else if (notfoundResponse.test(response))
                            _history.push('/notfound')
                        else if (errorResponse.test(response))
                            _history.push("/error")
                        else
                            return response;
                    }
                    )
                    .catch(error => {
                        _history.push("/error");
                        console.log(error)
                    });
            }         
        }).catch(error => console.log(error));
    })
}


async function callFetch(endpoint, options) {
    const _history = history;  
    return fetch(endpoint, options)
        .then(response => {
            if (response.status === 200)
                return response.json()
            else {
                return response.text()
            }
}).catch(error => {
            _history.push("/error");
            console.log(error)
        });
}

async function getAuthzToken(headers) {
    const _history = history;
    const authzToken = sessionStorage.getItem("authzToken");
    if (authzToken === undefined || authzToken === null || authzToken === "null" || authzToken ==="") {
        const options = {
            method: "GET",
            headers: headers,
        };        
        return fetch(appResources.appEndpoint + "/authz/authorize", options)
            .then(response => {
                if (response.status === 200)
                    return response.text();
                if (response === "" || noAccessResponse.test(response))
                    _history.push("/noaccess")
                else if (errorResponse.test(response))
                    _history.push("/error")          
            })
            .catch(error => {
                
                console.log(error)
                _history.push("/error");
            });
    }
    else {
        return authzToken;
    }
    
}

export async function getUserPermission(endpoint) {
    const userPermission = sessionStorage.getItem("userPermission");
    if (userPermission === undefined || userPermission === null || userPermission === "null" || userPermission === "") {
       return callApi(endpoint).then((response) => {
            //DataStore.setStoreValue(RouteConfig.name, response[0].setting_values);
           if (response) {
               sessionStorage.setItem("userPermission", response[0]?.setting_values)
           }
            return (response??[])[0]?.setting_values;
        })
    }
    else {
        return (userPermission??"").split(",");
    }
}

export function getUserPermissionSession() {
    return (sessionStorage.getItem("userPermission") ?? "").split(",");
}




export async function callApiWithTokenAsync(instance, account, apiEndpoint, callBackWithChunk) {
    MsalHelper.getAccessToken().then((accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", 'application/json');

        const options = {
            method: "GET",
            headers: headers
        };
        const callBackMethod = callBackWithChunk;
        fetch(appResources.appEndpoint + apiEndpoint, options)
            .then(async response => {

                const reader = response.body?.getReader();
                if (!reader) {
                    throw new Error('Failed to read response');
                }
                const decoder = new TextDecoder();
                let PartialItem = "";
                let level = 0;
                let JsonItems = [];
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    if (!value) continue;
                    const result = JsonStreamDecoderNew(decoder.decode(value), PartialItem, level);
                    PartialItem = result.PartialItem;
                    level = result.Level;
                    for (var j = 0; j < result.JsonItems.length; j++) {
                        JsonItems.push(result.JsonItems[j]);
                    }
                    setTimeout(function () { callBackMethod(JsonItems); }, 1000)

                }
                reader.releaseLock();
                //console.log(JsonItems.length);
            })
            .catch(error => console.log(error));
    })
}


const JTOKEN_START_OBJECT = '{';
const JTOKEN_END_OBJECT = '}';
function JsonStreamDecoderNew(chunk, partialItem, level) {
    let jsonItems = [];
    let itemStart = 0
    for (let i = 0; i < chunk.length; i++) {
        if (chunk[i] === JTOKEN_START_OBJECT) {
            if (level === 0) {
                itemStart = i;
            }
            level++;
        }
        if (chunk[i] === JTOKEN_END_OBJECT) {
            level--;
            if (level === 0) {
                let item = chunk.substring(itemStart, i + 1);
                if (partialItem) {
                    item = partialItem + item;
                    partialItem = '';
                }
                jsonItems.push(JSON.parse(item));
            }
        }
    }
    if (level !== 0) {
        partialItem = chunk.substring(itemStart);
    }
    return { JsonItems: jsonItems, PartialItem: partialItem, Level:level }
}

