import React,{ useEffect, useState, useRef, Suspense } from "react";
import {  useNavigate,useLocation, useParams } from "react-router-dom";
import {
    Box, Typography,
    Container, Stack
} from '@mui/material';
import { callApi } from "../fetch";
import useWindowDimensions from "../Hooks/UseWindowDimensions";
import { enumActions } from "../enums";
import { DataStore } from "../DataStore";
import {
    extractColumn,
    removeEmptyProperties,
    ResolveUrl
} from "../Utility";
import { AppCustomEvents } from "../AppCode/AppCustomEvents"
const DataGrid = React.lazy(() => import('../components/DataGrid/DataGrid').then(module => ({ default: module.DataGrid })));
const PageDrawer  = React.lazy(() => import('../components/Drawer/PageDrawer').then(module => ({ default: module.PageDrawer })));
const SearchForm = React.lazy(() => import('../components/Drawer/SearchForm').then(module => ({ default: module.SearchForm })));

export const Summary = (props) => {
    const { height } = useWindowDimensions();
    //const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = useParams();

    const dataGridRef = useRef();
    
   
    const configFile = props.config//toTitleCase(url.replace("/", "")) + "ListConfig";

    const [gridName, setGridName] = useState();
    const [gridRefresh, setGridRefresh] = useState();
    const [pageConfigName, setPageConfigName] = useState();
    const [showDrawer, setShowDrawer] = useState();

    let pageConfig = DataStore.getStoreValue(pageConfigName);
    //const gridData = DataStore.getStoreValue(gridName);
    

    const handleAction = (e,action,...rest) => {
        var componentProps = {
            handleAction: handleAction,
        }
        switch (action) {
            case enumActions.ShowSearchForm:
                EmptyComponent = () => {
                    return <SearchForm {...componentProps} formConfig={pageConfig.searchForm} formData={DataStore.getStoreValue(pageConfig.searchForm.name) } ></SearchForm>
                }
                setTimeout(() => { setShowDrawer(true); }, 10)
                break
            case enumActions.HideSearchForm:
                EmptyComponent = () => {
                    return ""
                }
                setTimeout(() => { setShowDrawer(false); }, 10)
                break;
            case enumActions.SearchData:
                /// first parameter is search object
                DataStore.setStoreValue(pageConfig.searchForm.name, { ...rest[0]});
                setTimeout(() => { setShowDrawer(false); }, 10)
                //use effect will load the data if server search field is in search object
                setGridRefresh("search");
                break;
            case enumActions.ResetSearch:
               // DataStore.removeStoreValue([pageConfig.searchForm.name]);
                //dataGridRef.current.SetFilterBy({});
                setGridRefresh("reset");
                setTimeout(() => {  setShowDrawer(false); }, 10)
                break;            
            case enumActions.Export:
                let exportData = dataGridRef.current.getGridData();
                import("../ExportExcel.js")
                    .then(Export => {
                        Export.ExportExcel(pageConfig.page.title, pageConfig.dataGrid.name, pageConfig.dataGrid, exportData, DataStore.getAppStoreValue("username") );
                    })
                break;               
            default:
               return <>Not implemented yet</>
                
        }
    }

    useEffect(() => {
        if (gridRefresh === "search") {
            let search = { ...DataStore.getStoreValue(pageConfig?.searchForm.name) }
            // filter by searchServer, if count >0 go for server search
            const serverSrchCol = pageConfig.searchForm.fields.filter(c => c.searchServer === true).map(c => c.name);
            const clientSrchCol = pageConfig.searchForm.fields.filter(c => c.searchServer !== true).map(c => c.name);
            const serverSearch = {
                data: removeEmptyProperties(extractColumn(search.data, serverSrchCol)),
                state: removeEmptyProperties(extractColumn(search.state, serverSrchCol))
            };

            const clientSearch = {
                data: removeEmptyProperties(extractColumn(search.data, clientSrchCol)), state: removeEmptyProperties(extractColumn(search.state, clientSrchCol))
            };

            if (serverSearch?.data && Object.keys(serverSearch?.data).length > 0) {
                const url = ResolveUrl(pageConfig.dataGrid.dataSource, GetPageObject(pageConfig.name));
                //const url = ReplaceURLParam(pageConfig.dataGrid.dataSource.url, serverSearch.data);
                DataStore.setStoreValue(pageConfig.dataGrid.name, { data: undefined });
                callApi(url).then((response) => {
                    let storObj =
                    {
                        config: {
                            ...pageConfig
                        },
                        data: response
                    }
                    DataStore.setStoreValue(pageConfig.dataGrid.name, { ...storObj });
                    dataGridRef.current.SetFilterBy({ ...clientSearch });
                    setGridRefresh(undefined);
                    storObj = null;
                })
            }
            else {
                dataGridRef.current.SetFilterBy({ ...clientSearch });
                setGridRefresh(undefined);
            }
            search = null;
        }
        else if (gridRefresh === "reset") {
            let search = { ...DataStore.getStoreValue(pageConfig?.searchForm.name) };
            const serverSrchCol = pageConfig.searchForm.fields.filter(c => c.searchServer === true).map(c => c.name);
            const serverSearch = {
                data: removeEmptyProperties(extractColumn(search.data, serverSrchCol)),
                state: removeEmptyProperties(extractColumn(search.state, serverSrchCol))
            };
            DataStore.removeStoreValue([pageConfig.searchForm.name]);
           
            if (serverSearch?.data && Object.keys(serverSearch?.data).length > 0) {
                DataStore.setStoreValue(pageConfig.dataGrid.name, { data: undefined });
                
                const url = ResolveUrl(pageConfig.dataGrid.dataSource, GetPageObject(pageConfig.name));
                //const url = ReplaceURLParam(pageConfig.dataGrid.dataSource.url, pageConfig.dataGrid.dataSource.defaultParam);
                callApi(url).then((response) => {
                    let storObj =
                    {
                        config: {
                            ...pageConfig
                        },
                        data: response
                    }
                    DataStore.setStoreValue(pageConfig.dataGrid.name, { ...storObj });
                    dataGridRef.current.SetFilterBy({});
                    setGridRefresh(undefined);
                    storObj = null;
                })
            }
            else {
                dataGridRef.current.SetFilterBy({});
                setGridRefresh(undefined);
            }
            search = null;
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRefresh])

    const GetPageObject = (pageName) => {
        return {
            name: pageName,
            configFileName: configFile,
            location: location.pathname,
            urlParams: urlParams,
            queryParams: location.search,
            callApi: callApi,
            dataStore: DataStore,
            navigate: navigate
        };
    }

    useEffect(() => {
        console.log("second")
        const onPageLoad = async (pgConfig) => {
            const onBeforePageLoadFn = pgConfig?.page?.onBeforeLoad ? AppCustomEvents[pgConfig.page.onBeforeLoad] ?? undefined : undefined;
            const onAfterPageLoadFn = pgConfig?.page?.onAfterLoad ? AppCustomEvents[pgConfig.page.onAfterLoad] ?? undefined : undefined;
            let isonPageLoadFnCompleted = true;
            const pageObject = GetPageObject(pgConfig.name);
            isonPageLoadFnCompleted = typeof onBeforePageLoadFn === "function" ? await onBeforePageLoadFn.call({ ...pageObject }) : await true;


            //goNext = await onPageLoad.apply();
            if (isonPageLoadFnCompleted) {
                if (componentMounted) {
                    setTimeout(() => setPageConfigName(pgConfig.name), 10)
                }
                const url = ResolveUrl(pgConfig.dataGrid.dataSource, { ...pageObject });
                if (url) {
                    let storObj =
                    {
                        config: {
                            ...pgConfig
                        },
                        data: await callApi(url)
                    }
                    //const response = await callApi(url)
                    if (dataLoaded) {
                        DataStore.setStoreValue(pgConfig.dataGrid.name, { ...storObj });
                    }
                    storObj = null;
                }
                isonPageLoadFnCompleted = typeof onAfterPageLoadFn === "function" ? await onAfterPageLoadFn.call({ ...pageObject }) : await true;
                if (dataLoaded) {
                    setGridName(pgConfig.dataGrid.name);
                }
                
            }
        }
        
        let componentMounted = true;
        let dataLoaded = true;
        import("../Configs/" + configFile + ".js")
            .then(config => {
                let pgConfig = config[configFile];
                DataStore.setStoreValue(pgConfig.name, { ...pgConfig });
                onPageLoad(pgConfig);
              
            }).catch(error => {
                console.log(error)
            });
        return () => {
            console.log("datastore cleaned up");
            const storekey = DataStore.getAllKeys();
            if (storekey.length>0)
                DataStore.removeStoreValues(storekey);
            
            componentMounted = false;
            dataLoaded = false;

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

   

    return (
        <> 
            <Container maxWidth="sm md lg xl" >

                {pageConfigName && <Stack spacing={0}>

                    {pageConfig?.page?.title && <Box sx={{ m: 2 }} data-qa-element="pageHeader">
                        <Typography variant="h6">{pageConfig?.page?.title}</Typography>
                    </Box>}
                    {pageConfig &&
                        <Box >
                            <DataGrid ref={dataGridRef} name={gridName} dataGridConfig={pageConfig?.dataGrid} gridData={DataStore.getStoreValue(gridName)} compHeight={height - 150} handleAction={handleAction} ></DataGrid>
                        </Box>}

                    {showDrawer === true ?
                        <Suspense fallback={<div>Loading...</div>}>
                        <PageDrawer show={showDrawer}
                        handleAction={handleAction}

                    ><EmptyComponent></EmptyComponent>

                    </PageDrawer></Suspense> : ""}
                </Stack>}
            </Container>

        </>
    )
};

export let EmptyComponent = () => { return <>Loading...</> }

