import React from "react";
import { Box, Toolbar } from '@mui/material';
import { Footer } from "./Footer/Footer"
import { AppNavBar } from "./AppBar/AppNavBar";

export const PageLayout = (props) => {
    return (
        <>
            <AppNavBar></AppNavBar>
            <Toolbar></Toolbar>
            <Box position="static" sx={{ mx: 'auto', flex: '1 0 auto', zIndex: 'tooltip'/*height: window.innerHeight - 100*/ }}>
                {props.children}
            </Box>
            <Footer></Footer>
        </>
    );
};