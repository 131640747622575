import { Dashboard } from "../Pages/Dashboard";
import { Detail } from "../Pages/Detail";
import { Summary } from "../Pages/Summary";
import { NoAccess } from "../Pages/NoAccess";
import { Error } from "../Pages/Error";
import { NotFound } from "../Pages/NotFound";

export const RouteConfig = {
    name: "routes",
    dataSource: {
        url: "/authz/settings/permission",
    }
,routes:[
    {
        id: 'P1',
        caption: "Home",
        icon: "home",
        url: "/",
        config:"HomePageConfig",
        component: Dashboard,
        type:"topmenu"
    },
    {
        id: 'P2',
        caption: "Customer",
        icon: "list_alt",
        url: "/customer",
        config: "SelectCustomerConfig",
        component: Detail
    },
    {
        id: 'P3',
        caption: "Orders",
        icon: "list_alt",
        url: "/:customerid/order",
        config: "OrderListConfig",
        component: Summary,
        type: "topmenu",
        restricted:true,
    },
    {
        id: 'P4',
        caption: "Inventory",
        icon: "inventory_2",
        url: "/:customerid/inventory",
        config: "InventoryListConfig",
        component: Summary,
        type: "topmenu",
        restricted: true,
    },
    {
        id: 'P5',
        caption: "Shipments",
        icon: "local_shipping",
        url: "/:customerid/shipment",
        config: "ShipmentListConfig",
        component: Summary,
        type: "topmenu",
        restricted: true,
    },
    {
        id: 'P6',
        caption: "Invoices",
        icon: "receipt",
        url: "/:customerid/invoice",
        config: "InvoiceListConfig",
        component: Summary,
        type: "topmenu",
        restricted: true,
    },
    {
        id: 'P7',
        caption: "Orders",
        icon: "list_alt",
        url: "/:customerid/order/:id",
        config: "OrderDetailConfig",
        component: Detail,
        restricted: true,
    },
    {
        id: 'P8',
        caption: "Inventory",
        icon: "inventory_2",
        url: "/:customerid/inventory/:id",
        component: Detail,
        restricted: true,
    },
    {
        id: 'P9',
        caption: "Shipments",
        icon: "local_shipping",
        url: "/:customerid/shipment/:id",
        config: "ShipmentDetailConfig",
        component: Detail,
        restricted: true,
    },
    {
        id: 'P10',
        caption: "Invoices",
        icon: "receipt",
        url: "/:customerid/invoice/:id",
        component: Detail,
        restricted: true,
    },
    
    {
        id: 'P11',
        caption: "NoAccess",
        icon: "",
        url: "/noaccess",
        component: NoAccess,
        
    },
    {
        id: 'p12',
        caption: "Error",
        icon: "",
        url: "/error",
        component: Error,
        
    },
    {
        id: 'p13',
        caption: "Not Found",
        icon: "",
        url: "/notfound",
        component: NotFound,

    },
]
}