import { Router } from "react-router-dom";
import { useRef, useState, useLayoutEffect } from "react";
import history from "./history"


export function CustomBrowserRouter({ basename, children }) {
  const historyRef = useRef();
  if (historyRef.current == null) {
    historyRef.current = history;
  }
  const _history = historyRef.current;
  const [state, setState] = useState({
    action: _history.action,
    location: _history.location
  });

  useLayoutEffect(() => _history.listen(setState), [_history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}