import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      //  clientId: process.env.REACT_APP_ID, //"a9a9a9c8-605e-407a-877a-dd548aba6503",
      //  authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENENT_ID,      //93fd1cce-d1bd-42e0-9dbf-c622a6083db3", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      //redirectUri: "/",
      //postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      //  navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

        clientId: process.env.REACT_APP_ID,//"a2461413-46b8-428e-a964-c74699ff2d2a", // This is the ONLY mandatory field that you need to supply.
        authority: "https://sonocob2ctest.b2clogin.com/sonocob2ctest.onmicrosoft.com/B2C_1_portal_susi", // Choose SUSI as your default authority.
        knownAuthorities: ["sonocob2ctest.b2clogin.com"], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.

    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              };
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      //console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          }
      }
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   //scopes: ["User.Read"]
      scopes: ["openid"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

  export const appResources = {
      appEndpoint: process.env.REACT_APP_API_URL,
      scopes: [process.env.REACT_APP_API_SCOPE]
  }

export const appConfig = {
    name: "Partner Portal",
}